import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>David Phillips</h1>
    <p>Welcome to my site.</p>
    <StaticImage
      src="../images/galah-on-fence.jpg"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Galah on a fence"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/projects/">Projects</Link> <br />
    </p>
  </Layout>
)

export default IndexPage
